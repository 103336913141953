import * as React from "react"
import SearchBox from "./SearchBox"
import { StaticImage } from "gatsby-plugin-image"
import SignInModal from "./modals/SignInModal"
import firebase from "firebase"
import config, { firebaseInit } from "../config"
import { Link } from "gatsby"
import { Menu, MenuItem } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { HiCog } from "@react-icons/all-files/hi/HiCog"
import { ImHammer2 } from "@react-icons/all-files/im/ImHammer2"
import { BiLogOut } from "@react-icons/all-files/bi/BiLogOut"
import { BsGiftFill } from "@react-icons/all-files/bs/BsGiftFill"
import BottomNavigation from "./navigation/BottomNavigation"
import PerfilForm from "./modals/PerfilFormModal"
import { createAccess, createContentViews } from "../api/content"
import "./navBar.css"

const baseUri = config.serverUri

const CustomLink = ({ to, children }) => {
  const handleClick = e => {
    if (window.location.pathname === to) {
      e.preventDefault()
      window.location.reload()
    }
  }

  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  )
}

const CustomLink2 = ({ to, children }) => {
  const handleClick = e => {
    if (window.location.pathname === to) {
      e.preventDefault()
      window.location.reload()
    }
  }

  return (
    <Link
      className={`block mt-4 md:inline-block md:mt-0 text-white mr-5`}
      to={to}
      onClick={handleClick}
    >
      {children}
    </Link>
  )
}

const ContentPageLayout = ({
  children,
  location,
  suscription,
  section,
  sectionSelected,
}) => {
  const profileUri = baseUri + "/users"
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [avatar, setAvatar] = React.useState()
  const [optionsOpen, setOptionsOpen] = React.useState(false)
  const [showPreferences, setShowPreferences] = React.useState(false)
  const [nameUser, setNameUser] = React.useState(null)

  const [userToken, setUserToken] = React.useState(null)

  React.useEffect(() => {
    // const b2bLogin_Time = localStorage.getItem('B2B_LOGIN_TIME');
    // if (b2bLogin_Time !== null) {
    //   console.log('b2bLogin_Time', b2bLogin_Time);
    //   const difference = new Date().getTime() - parseInt(b2bLogin_Time);
    //   console.log(difference + " difference");
    //   const minutes = Math.round(difference / 60000);
    //   console.log(minutes + " minutes");
    //   if(minutes > 2 ){
    //     // TODO: Register on endpoint.

    //     // TODO: Clear localstorage.
    //   }
    // }

    const b2bPlayTime = JSON.parse(localStorage.getItem("B2B_PLAY"))
    if (b2bPlayTime !== null) {
      const difference = new Date().getTime() - parseInt(b2bPlayTime.start)
      const seg = Math.floor(difference / 1000)
      if (seg > 120) {
        const data = {
          contenidoId: "" + b2bPlayTime.content.id,
          contenido: "" + b2bPlayTime.content.nombre,
          tiempo: seg - 120,
        }
        // console.log('save')
        // console.log(data)
        // console.log(b2bPlayTime.token)
        createContentViews(b2bPlayTime.token, data).then(res => {
          console.log(res)
        })
        localStorage.removeItem("B2B_PLAY")
      } else {
        // console.log('no save')
        localStorage.removeItem("B2B_PLAY")
      }
    }
  }, [location])

  React.useEffect(() => {
    if (window.firstUse === undefined || window.firstUse === false) {
      let token = userToken
      if (userHasSuscription !== null && userHasSuscription !== false) {
        if (window) {
          window.firstUse = true

          window.setTimeout(() => saveAcess(token), 120000)
        }
      }
    } else {
    }
  }, [userHasSuscription])

  const saveAcess = token => {
    createAccess(token).then(res => {
      console.log(res)
    })
  }

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Cierre de sesión exitoso
        setAnchorEl(null)

        if (window) {
          window.firstUse = false
          // Recargar la página después de cerrar sesión
          window.location.reload()
        }
      })
      .catch(error => {
        // Manejar cualquier error durante el cierre de sesión
        console.error("Error al cerrar sesión: ", error)
      })
  }

  //metodos para cambio de pass

  const [showSignInForm, setShowSignInForm] = React.useState(false)
  const promtSignIn = () => {
    setShowSignInForm(true)
  }
  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true).then(token => {
          setUserToken(token)
          // console.log("token")
          // console.log(token)
        })
        setUser(user)
        fetch(profileUri + `?email=${user.email}`)
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                const account = data[0]
                if (account) {
                  setNameUser(account.nombre)

                  // console.log(data[0].suscription)
                  // console.log(account)
                  setUserHasSuscription(account.suscription)
                  // suscription(account.suscription);
                  if (account.avatar && account.avatar.slice(0, 4) === "http")
                    setAvatar(account.avatar)
                  else if (!account.avatar) setAvatar(account.avatar)
                  else setAvatar(`${baseUri}${account.avatar}`)

                  if (
                    (account.gustos === null || account.gustos === undefined) &&
                    account.suscription !== "free" &&
                    account.suscription !== null
                  ) {
                    // console.log(account.suscription)
                    // console.log(account.preferences)
                    setShowPreferences(true)
                  }
                }
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else setUser(null)
    })
  }, [user])

  return (
    <div>
      <header>
        <div className="bg-black " style={{ position: "relative" }}>
          <nav
            className="flex items-center justify-between w-full flex-wrap bg-teal-500 p-6"
            style={{ gap: "20px" }}
          >
            <div className="flex items-center flex-shrink-0 text-white mr-6">
              <CustomLink to="/">
                <StaticImage
                  width={96}
                  src="../images/logo_white.png"
                  alt="Shift Logo"
                ></StaticImage>
              </CustomLink>
            </div>

            <div className="w-full display-validation flex-grow md:flex md:items-center md:w-auto">
              <div className="text-sm md:flex-grow">
                <CustomLink2 to="/sections/entrenamiento">
                  Entrenamiento
                </CustomLink2>
                <CustomLink2 to="/sections/menteycuerpo">
                  Mente y cuerpo
                </CustomLink2>
              </div>

              <div>
                <SearchBox
                  username={nameUser}
                  section={section}
                  sectionSelected={sectionSelected}
                ></SearchBox>
              </div>

              {user ? (
                <div className="flex flex-col h-8 items-center mr-4">
                  <Link to="/profile">
                    {avatar ? (
                      <div>
                        <img
                          className="object-cover w-8 h-8 rounded-full"
                          src={avatar}
                          alt="Foto de perfil"
                        />
                      </div>
                    ) : (
                      <div className="object-cover w-8 h-8 mr-0 mt-0 rounded-full">
                        <StaticImage
                          src="../images/userprofile.png"
                          alt="Foto de perfil"
                        />
                      </div>
                    )}
                  </Link>
                </div>
              ) : (
                <div>
                  <a
                    href="#"
                    onClick={promtSignIn}
                    className="inline-block bg-sc-primary hover:bg-sc-primary-200 text-sm px-4 py-2 leading-none border border-sc-primary rounded-full text-white  hover:border-transparent hover:text-teal-500 mt-4 mr-4 md:mt-0"
                  >
                    Iniciar sesión
                  </a>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
                  onClick={() => {
                    setOptionsOpen(true)
                  }}
                >
                  <svg
                    className="fill-current h-3 w-3"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex display-validation-true justify-end flex-grow">
              <div>
                <SearchBox
                  section={section}
                  sectionSelected={sectionSelected}
                ></SearchBox>
              </div>
              <button
                className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
                onClick={() => {
                  setOptionsOpen(true)
                }}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>

            <div>
              {optionsOpen ? (
                <div
                  className="fixed top-0 right-0 w-full h-full z-50"
                  style={{ zIndex: "5000000" }}
                >
                  <div className="fixed top-0 right-0 z-0 h-full w-full  bg-black bg-opacity-50"></div>
                  <div className="fixed top-0 right-0 z-0  bg-sc-menu-bg text-black h-full w-9/12  md:w-1/4 p-4">
                    <div className="flex justify-end">
                      <CloseIcon
                        color="#ffffff"
                        fontSize="large"
                        onClick={() => {
                          setOptionsOpen(false)
                        }}
                      ></CloseIcon>
                    </div>
                    <div className="flex flex-col md:flex-grow p-8 w-full justify-between cursor-pointer">
                      <div>
                        {user ? (
                          <Link
                            to="/settings"
                            className="block mt-4 text-sc-black md:mt-0 mr-0 mb-8"
                            style={{ fontSize: 16 }}
                          >
                            <span>
                              <HiCog className="mr-2 inline-block" size={18} />
                              Configuración
                            </span>
                          </Link>
                        ) : null}
                        <Link
                          to="/settings/legal"
                          className="block mt-4 text-sc-black  md:mt-0  mr-5 mb-8"
                          style={{ fontSize: 16 }}
                        >
                          <span>
                            <ImHammer2 className="mr-2 inline-block"></ImHammer2>
                            Legales
                          </span>
                        </Link>
                        {user && userHasSuscription == "free" ? (
                          <Link
                            to="/code"
                            className="block mt-4 text-sc-black md:mt-0 mr-0 mb-8"
                            style={{ fontSize: 16 }}
                          >
                            <span>
                              <BsGiftFill
                                className="mr-2 inline-block"
                                size={18}
                              />
                              Código Promocional
                            </span>
                          </Link>
                        ) : null}
                      </div>
                      {!user ? (
                        <div>
                          <Link
                            to="/pwa/account/login"
                            className="inline-block display-validation-true display-validation-true  bg-sc-primary text-sm px-4 py-2 leading-none border border-sc-primary rounded-full text-white  hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 md:mt-0"
                          >
                            Iniciar sesión
                          </Link>
                        </div>
                      ) : null}
                      <div
                        className="absolute bottom-0 pb-8"
                        style={{ width: "80%" }}
                      >
                        <div className="flex flex-col justify-between h-32">
                          <div>
                            {user ? (
                              <div
                                className="block mt-4 text-sc-black  md:mt-0 cursor-pointer mr-5"
                                style={{ fontSize: 16 }}
                                onClick={() => {
                                  signOut()
                                  setOptionsOpen(false)
                                }}
                              >
                                <span>
                                  <BiLogOut className="mr-2 inline-block"></BiLogOut>
                                  Cerrar sesión
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <div className="flex justify-end mr-4">
                            <div>
                              By Ironbit {new Date().getFullYear().toString()} v
                              {config.version}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </nav>

          <BottomNavigation
            className="fixed w-full z-40 bottom-0 display-validation-true"
            location={location}
          ></BottomNavigation>
        </div>
      </header>
      <main>{children}</main>
      <footer></footer>
      <SignInModal
        show={showSignInForm}
        onClose={() => {
          setShowSignInForm(false)
        }}
      />
      {userHasSuscription === "premium" ? (
        <PerfilForm
          show={showPreferences}
          ispwaComponent={true}
          onClose={() => {
            setShowPreferences(false)
          }}
        />
      ) : null}
    </div>
  )
}

export default ContentPageLayout
