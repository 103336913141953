import React, { useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons"

const SearchBox = ({ username, section, sectionSelected }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const searchRef = useRef()
  const searchTermRef = useRef(searchTerm)

  // Mantén la referencia actualizada
  useEffect(() => {
    searchTermRef.current = searchTerm
  }, [searchTerm])

  const handleInputChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleSearchClick = () => {
    const urlParams = new URLSearchParams(window.location.search)
    let sectionId = urlParams.get("sectionId")

    if (sectionSelected === undefined) {
      navigate(
        `/search?term=${encodeURIComponent(
          searchTerm
        )}&sectionId=${encodeURIComponent(sectionId)}`
      )
    } else {
      sectionId =
        sectionSelected === "home"
          ? "60f7ba875e39aa6aa19a36af"
          : sectionSelected
      navigate(
        `/search?term=${encodeURIComponent(searchTerm)}&sectionId=${sectionId}`
      )
    }
  }

  const closeSearch = () => {
    setIsSearchOpen(false)
  }

  const handleOutsideClick = e => {
    if (
      searchRef.current &&
      !searchRef.current.contains(e.target) &&
      !searchTermRef.current.trim()
    ) {
      closeSearch()
    }
  }

  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener("mousedown", handleOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [isSearchOpen])

  return (
    <div ref={searchRef} className="relative flex items-center text-white pr-5">
      {!isSearchOpen && (
        <div
          className="flex items-center cursor-pointer transition-transform duration-300"
          onClick={() => setIsSearchOpen(true)}
        >
          {username && (
            <span className="mr-2 text-white animate-fadeIn">
              ¡Hola {username}!
            </span>
          )}

          <SearchIcon className="text-white" />
        </div>
      )}

      {isSearchOpen && (
        <div className="relative flex items-center bg-transparent w-full max-w-md transition-all duration-300">
          <button
            className="absolute left-0 ml-3 text-white"
            onClick={handleSearchClick}
          >
            <SearchIcon />
          </button>
          <input
            type="text"
            placeholder="Buscar..."
            className="bg-transparent border-b border-white w-full py-1 px-10 text-white placeholder-white focus:outline-none"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyUp={e => e.key === "Enter" && handleSearchClick()}
            autoFocus
          />
          <button
            className="absolute right-0 mr-6 text-white"
            onClick={() => {
              setSearchTerm("")
              closeSearch()
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  )
}

export default SearchBox
